
// import Slick Slider JS
import "slick-carousel";
import jQuery from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import(/* webpackPrefetch: true */ "../../utils/numberCount.js");

jQuery(document).ready(function ($) {

  //---------------------------------------------
  if ($(".slider-customers-using-the-solution").length > 0 && $(window).width() < 992) {
    $(".slider-customers-using-the-solution").slick({
      autoplaySpeed: 5000,
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
  }
  if ($(".slider-reviews").length > 0 && $(window).width() < 992) {
    $(".slider-reviews").slick({
      autoplaySpeed: 5000,
      dots: true,
      arrows: false,
      infinite: true,
    //   autoplay: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }
});
